/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-new-func */
/* eslint-disable import/extensions */
import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { isMobile } from 'react-device-detect';
import CustomEvent from '~/easy-components/Form/components/CustomEvent';
import useFieldBase from '~/hooks/useField';
import useUiDesigner from '~/hooks/useUiDesigner';
import { Title, FieldContainer } from '../styles';
import { Input } from './styles';
// eslint-disable-next-line import/no-cycle
import UserField from '../UserField';
import { sendEvent } from '../../HandlerEvent';

function CheckboxElement({
  valueChecked,
  valueUnchecked,
  baseName,
  name,
  label,
  hidden,
  formRef,
  mainFormRef,
  settings,
  labelWidth,
  readOnly: readOnlyProp,
  onChange,
  disableRenderMobile,
  height,
  auxScope,
  // customTabName,
  required = false,
  isUserField,
  customTabName,
  ...rest
}) {
  const inputRef = useRef(null);

  const pageId = settings ? settings._route : '';

  const {
    selfField,
    viewMode,
    showContextMenu,
    HasEventComponent,
  } = useUiDesigner({
    pageId,
    componentType: 'field',
    baseName,
    name,
    title: label,
    isUserField,
    customTabName,
  });

  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  const { isHidden, isReadOnly } = useFieldBase({
    hidden,
    readOnly: readOnlyProp,
    required,
    selfField,
    formRef,
    inputRef,
    getLogData: () => {
      return {
        baseName,
        name: fieldName,
        value: inputRef.current.value,
      };
    },
  });

  const fieldTitle = selfField.title || label;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      clearValue: el => {
        el.checked = false;
      },
      setValue: (el, v) => {
        el.checked = v === valueChecked;
      },
      getValue: el => {
        return el.checked ? valueChecked : valueUnchecked;
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerOnChange = async params => {
    const response = await sendEvent({
      settings,
      eventName: 'onchange',
      params,
      run: params.run,
      element: inputRef.current,
      formRef,
      mainFormRef,
      events: selfField.events,
    });

    return response || params.value;
  };

  async function onChangeHandler(params) {
    const { value, checked } = params.currentTarget;

    let val = value;

    const newValue = await handlerOnChange({
      value: val,
      run: 'before',
      checked,
    });

    if (newValue !== undefined) val = newValue;

    if (onChange) {
      const returnValue = await onChange(checked, val);
      if (returnValue !== undefined) val = returnValue;
    }

    const afterValue = await handlerOnChange({
      value: val,
      run: 'after',
      checked,
    });

    if (afterValue !== undefined) val = afterValue;

    return val;
  }

  return (
    <>
      <FieldContainer
        hidden={isHidden}
        readOnly={isReadOnly}
        viewMode={!hidden || isUserField ? viewMode : 'normal'}
        onContextMenu={event => {
          showContextMenu({ event });
        }}
        flexible={false}
        disableRenderMobile={disableRenderMobile}
        height={height}
        isCheckBox
        hasCustomEvent={!!selfField.enableCustomEvent}
        style={
          isMobile
            ? {
                ...(selfField && selfField.style ? selfField.style : {}),
                ...(rest && rest.style ? rest.style : {}),
                alignItems: 'center',
              }
            : {
                height,
                alignItems: 'center',
              }
        }
      >
        <Input
          type="checkbox"
          ref={inputRef}
          defaultChecked={defaultValue === valueChecked}
          {...selfField}
          {...rest}
          hidden={false}
          onChange={onChangeHandler}
          disabled={isReadOnly}
        />

        {fieldTitle && (
          <Title
            highlight={isUserField && viewMode === 'design'}
            hideLine
            labelWidth={
              (selfField && selfField.labelWidth) || labelWidth || 130
            }
          >
            <div className="title">
              {fieldTitle}
              <div className="title-icon">
                <CustomEvent
                  settings={settings}
                  selfField={selfField}
                  inputRef={inputRef}
                  formRef={formRef}
                  mainFormRef={mainFormRef}
                  auxScope={auxScope}
                  style={{
                    marginLeft: isMobile ? '5px' : '0',
                  }}
                />
              </div>
              <HasEventComponent selfField={selfField} viewMode={viewMode} />
            </div>
            {error && <div className="error">{error}</div>}
          </Title>
        )}
      </FieldContainer>

      <UserField
        baseName={baseName}
        fieldName={fieldName}
        formRef={formRef}
        mainFormRef={mainFormRef}
        settings={settings}
        labelWidth={(selfField && selfField.labelWidth) || labelWidth || 130}
      />
    </>
  );
}

CheckboxElement.propTypes = {
  valueChecked: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  formRef: PropTypes.shape(),
  onChange: PropTypes.func,
  baseName: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hidden: PropTypes.bool,
  settings: PropTypes.shape(),
  labelWidth: PropTypes.any,
  readOnly: PropTypes.bool,
  disableRenderMobile: PropTypes.bool,
  height: PropTypes.string,
  mainFormRef: PropTypes.shape(),
  valueUnchecked: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CheckboxElement.defaultProps = {
  valueUnchecked: 'N',
  valueChecked: 'Y',
  formRef: null,
  baseName: null,
  settings: null,
  onChange: () => {},
  label: null,
  labelWidth: null,
  readOnly: false,
  hidden: false,
  disableRenderMobile: false,
  height: '32px',
  mainFormRef: {},
};

export default memo(CheckboxElement);
