/* eslint-disable react/prop-types */
import React, { useRef, useImperativeHandle, forwardRef } from 'react';

// eslint-disable-next-line import/no-cycle
import ShowReport from '~/easy-components/ShowReport';

function ShowReportWrapper(
  {
    reportOptions = {},
    onConfirm,
    settings = {},
    dispatchConfirmValidation,
    showLoading,
    t,
    auxScope,
  },
  ref
) {
  const reportRef = useRef({});

  useImperativeHandle(ref, () => ({
    onConfirm: () =>
      onConfirm({
        data: reportRef.current.getData(),
        getSelectedData: reportRef.current.getSelectedData,
      }),
  }));
  return (
    <ShowReport
      ref={reportRef}
      title={reportOptions.title}
      data={JSON.stringify(reportOptions.data || [])}
      t={t}
      settings={settings || {}}
      reportSettings={
        reportOptions.settings || reportOptions.reportSettings || {}
      }
      onChange={dispatchConfirmValidation}
      onOpenInputLinkerInLine={reportOptions.onOpenInputLinkerInLine}
      auxScope={auxScope || reportOptions.auxScope}
      showLoading={showLoading}
    />
  );
}

export default forwardRef(ShowReportWrapper);
